export const getDropValue = (array, label, value) => {
    let options = [];
    for(const data of array){
        options.push({
            label: data[label],
            value: data[value]
        })
    }
    return options;
}

export const getProgramsData={
    id:'',
    name: '',
    university_id: '',
    degree_id: '',
    language_id:'',
    start_date: '',
    deadline: '',
    duration:'',
    scholarship_option: '0',
    study_mode:'1',
    disciplines: [],
    requirements:{
        program_id:'',
        required_education_degree_id: '',
        gpa:'',
        ib: '',
        a_level: '',
        ap:'',
        ielts: '',
        toefl: '',
        gre: '',
        gmat: '',
        sat:'',
        dim:'',
        ossd:'',
        ielts_ukvi:'',
        foundation:''
    },
    scholarship:{
        program_id:'',
        amount: '',
        req_edu_degree_id: '',
        gpa:'',
        ib: '',
        a_level: '',
        ap: '',
        ielts: '',
        toefl: '',
        gre: '',
        gmat: '',
        sat:'',
        dim: '',
        ossd: '',
    },
}

export const getZeroPad=(num)=>{
    if(num<10){
        return `0${num}`;
    }else{
        return num;
    }
}

export const getColor=(role)=>{
    if(role == 'student'){
        return '#06cf9c';
    }else if(role == 'teacher' || role == 'head_teacher'){
        return 'text-[#c89631]';
    }else if(role == 'parent'){
        return 'text-[#7f66ff]';
    }else if(role == 'company_head'){
        return 'text-[#53bdeb]';
    }else if(role == 'office_manager'){
        return 'text-[#007bfc]';
    }else {
        return 'text-[#ee7100]';
    }
}
export const getRole=(role)=>{
    if(role == 'student'){
        return 'Student';
    }else if(role == "teacher"){
        return 'Teacher';
    }else if(role == "xtm"){
      return 'XTM';
    }else if(role == "manager"){
      return 'Manager';
    }
}

export const getStartFrom = (degree_id, uni)=>{
    if(degree_id ==3 || degree_id ==2){
        return uni?.bachelour_start
    }else if(degree_id == 4){
        return uni?.master_start
    }else if(degree_id == 5){
        return uni?.master_start
    }else if(degree_id == 6){
        return uni?.phd_start
    }
}

export const getStudyMode=()=>{
    return [{label:"Full time",value:1}, {label:"Online", value:2}, {label:"Part time", value:3}]
}

export const getStartFee = (phd_start, master_start, pre_master_start, bachelour_start) =>{
    let fee = [];
    if(phd_start && phd_start !=null){  fee.push(phd_start) }
    if(master_start && master_start !=null){   fee.push(master_start)   }
    if(pre_master_start && pre_master_start !=null){  fee.push(pre_master_start)  }
    if(bachelour_start && bachelour_start !=null){ fee.push(bachelour_start)   }
    if(fee.length !=0){
      return Math.min(...fee)
    }else{
      return 0
    }
    
  }

  export const getMoneyFormat=(amount)=>{
    if(amount){
        var parts = amount?.toString().split(".");
        parts[0]=parts[0].replace(/\B(?=(\d{3})+(?!\d))/g,",");
        return parts.join(",");
   }
  }

  export const zeroPadFunction=(num) =>{
    if(num<10){
        return `0${num}`;
    }else{
        return num;
    }
  }

  export const getPathConvert = (title="") => {
    // console.log(title)
    let lower = title?.toLowerCase();
    let str = lower?.replaceAll(" ", "_");
    return str;
  }


  const company_path = [
    {
      "id": 2,
      "path": "/celt-khatai"
    },
    {
      "id": 4,
      "path": "/celt-colleges-28-may/"
    },
    {
      "id": 5,
      "path": "/celt-colleges-iceri-seher/"
    },
    {
      "id": 6,
      "path": "/celt-colleges-sahil-metrosu/"
    },
    {
      "id": 7,
      "path": "/celt-colleges-bakixanovda/"
    },
    {
      "id": 8,
      "path": "/celt-nizami/"
    },
    {
      "id": 9,
      "path": "/celt-colleges-ecemi/"
    },
    {
      "id": 10,
      "path": "/celt-colleges-narimanov/"
    },
    {
      "id": 11,
      "path": "/celt-colleges-ingilab/"
    },
    {
      "id": 12,
      "path": "/celt-colleges-elmler/"
    },
    {
      "id": 13,
      "path": "/celt-hazi-aslanov/"
    },
    {
      "id": 14,
      "path": "/celt-colleges-sumqayit/"
    },
    {
      "id": 17,
      "path": "/celt-kids-narimanov-2"
    },
    {
      "id": 18,
      "path": "/celt-kids-elmler-2/"
    },
    {
      "id": 22,
      "path": "/celt-kids-sahil/"
    },
    {
      "id": 23,
      "path": "/celt-high-school-3/"
    },
    {
      "id": 29,
      "path": "/celt-colleges-knightsbridge/"
    },
    {
      "id": 30,
      "path": "/celt-colleges-ganjlik"
    },
    {
      "id": 33,
      "path": "/celt-ag-sheher"
    },
    {
      "id": 35,
      "path": "/celt-high-school-bilgah"
    }
  ]
  
  export const getCompanyPath = (id) => {
    const path = company_path.find(x => x.id == id);
    if (path) {
      return path["path"];
    } else {
      return `/contact/${id}`;
    }
  }



  export const getTranslateLanguage = (lang_id, property, data)=>{
    let last_value = "";
    if(lang_id == 1){
      last_value = "";
    }else if(lang_id == 2){
      last_value = "_en"
    }else if(lang_id == 3){
      last_value = "_ru"
    }else if(lang_id == 4){
      last_value = "_tr"
    }
    let column = property+last_value;
    if(data[column]){
      return data[column];
    }else{
      return data[property];
    }
  }


export function formatPhoneNumber(phoneNumber) {
  if(phoneNumber){
     return phoneNumber.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '$1$2 $3 $4 $5');
  }else{
    return "";
  }
 
}