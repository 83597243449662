import React, { Component } from 'react'
import Header from './components/Header'
import { resetStateValue } from '../../redux/MainReducer';
import { connect } from 'react-redux';

export class StudenMoreInfoModal extends Component {

    closeBtn=()=>{
        this.props.resetStateValue({
            name:"studentMoreInfoModalData"
        })
    }
  render() {
    const {studentMoreInfoModalData, student_notes} = this.props;
    console.log(student_notes)
    return (
      <div className='background_shadow flex justify-center items-center'>
        <div className='w-[400px] bg-white rounded-[10px]'>
            <Header closeBtn={this.closeBtn.bind(this)} title="Student info" />
            <div className='p-[10px]'>
                <ul className='flex flex-col gap-[8px]'>
                    <li className='flex items-center gap-[6px]'>
                        <span className='font-[600]'>Email:</span>
                        <span>{studentMoreInfoModalData?.email}</span>
                    </li>
                    <li className='flex items-center gap-[6px]'>
                        <span className='font-[600]'>Phone number:</span>
                        <span>{studentMoreInfoModalData?.phone_number}</span>
                    </li>
                    <li className='flex items-center gap-[6px]'>
                        <span className='font-[600]'>Register type:</span>
                        <span>{studentMoreInfoModalData?.register_type}</span>
                    </li>
                    <li className='flex items-center gap-[6px]'>
                        <span className='font-[600]'>Register date:</span>
                        <span>{studentMoreInfoModalData?.registerDate}</span>
                    </li>
                    {
                        studentMoreInfoModalData?.verify_type?
                        <li className='flex items-center gap-[6px]'>
                            <span className='font-[600]'>Verify type:</span>
                            <span>{studentMoreInfoModalData?.verify_type}</span>
                        </li>:null
                    }
                    
                </ul>
                {
                    student_notes?.length !==0?
                    <div>
                        <span className='inline-block w-full text-center font-[600]'>Qeydlər</span>
                        {
                            student_notes?.map((data, i)=>(
                                <div key={i} className='text-[14px] border-b pt-[5px] pb-[5px]' dangerouslySetInnerHTML={{__html: data?.note}}></div>
                            ))
                        }
                    </div>:null
                }
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentMoreInfoModalData: state.Data.studentMoreInfoModalData,
    student_notes: state.Data.student_notes,
});
const mapDispatchToProps = {resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(StudenMoreInfoModal)
